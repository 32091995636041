import { createContext, FC, ReactNode, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

const AppContext = createContext<any>({} as any);

const AppProvider: FC<{ children?: ReactNode }> = props => {
  const [isModalTurnedOff, setIsModalTurnedOff] = useLocalStorage(
    'isModalTurnedOff',
    false
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(!isModalTurnedOff);

  return (
    <AppContext.Provider
      value={{
        isModalTurnedOff,
        setIsModalTurnedOff,
        isModalOpen,
        setIsModalOpen,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
