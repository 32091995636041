import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n/config';
import { AccountProvider } from 'auth/accountProvider';
import { AppProvider } from 'appProvider';

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <AccountProvider>
        <App />
      </AccountProvider>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
