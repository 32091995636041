import { FC } from 'react';
import './Footer.scss';

const Footer: FC = () => {
  return (
    <footer className="footer" data-testid="footer">
      <div className="logo visma-logo" />
      <div className="logo uc-logo" />
    </footer>
  );
};

export default Footer;
