import { FC, useContext, useEffect, useRef, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { Report, models } from 'powerbi-client';
import { useTranslation } from 'react-i18next';
import './Reports.scss';
import { AccountContext } from 'auth/accountProvider';

const Reports: FC = () => {
  const { t } = useTranslation();
  const { userInfo } = useContext(AccountContext);

  const reportsBlockRef = useRef<HTMLDivElement>(null);
  const [reportComponent, setReportComponent] = useState<Report>();

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log(reportComponent);
    }
  }, [reportComponent]);

  return (
    <div className="reports container">
      <h1>{t('reportsPageTitle')}</h1>
      <div ref={reportsBlockRef} className="reports__block">
        {userInfo?.reportId && (
          <PowerBIEmbed
            embedConfig={{
              type: 'report',
              id: userInfo.reportId,
              embedUrl: userInfo.embedUrl,
              accessToken: userInfo.embedToken,
              tokenType: models.TokenType.Embed,
              settings: {
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                background: models.BackgroundType.Transparent,
                layoutType: models.LayoutType.Custom,
                customLayout: {
                  displayOption: models.DisplayOption.FitToWidth,
                },
              },
            }}
            cssClassName="reports__embed"
            getEmbeddedComponent={embeddedReport => {
              setReportComponent(embeddedReport as Report);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Reports;
