import axios from 'axios';
import { useCallback, useContext, useState } from 'react';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useTranslation } from 'react-i18next';
import { AccountContext } from 'auth/accountProvider';
import { allowedExtensions, fileUploadUrl, MAX_FILE_SIZE } from './constants';
import { registerLocale } from 'react-datepicker';
import format from 'date-fns/format';
import nl from 'date-fns/locale/nl';
import enUS from 'date-fns/locale/en-US';

export interface IFilesProps {
  uploadedFile: File | null;
  draggedFile: File | null;
  fileToSend: File | null;
}

const initialFilesState = {
  uploadedFile: null,
  draggedFile: null,
  fileToSend: null,
};

export enum uploadTypes {
  REPLACE = 'replace',
  ADD = 'add',
}

export const useUpload = (template: string) => {
  const { t } = useTranslation();
  const fileExtensionErrorMsg = t('This file type is not supported');
  const fileSizeErrorMsg = t('File is larger than 4.49MB');
  const pleaseAttachFile = t('Please attach a file');

  const [uploadError, setUploadError] = useState<string>('');
  const [files, setFiles] = useState<IFilesProps>(initialFilesState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string>('');
  const [infoMsg, setInfoMsg] = useState<string>('');

  const [uploadType, setUploadType] = useState<string>(uploadTypes.REPLACE);

  const DATE_FORMAT = 'yyyy-MM';

  // Date picker locales
  registerLocale('nl', nl);
  registerLocale('en', enUS);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const { getSession } = useContext(AccountContext);

  const clearPrevState = () => {
    setFiles(initialFilesState);
    setUploadError('');
    setInfoMsg('');
    setSuccessMsg('');
    setStartDate(new Date());
    setEndDate(new Date());
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearPrevState();
    const file = e.target.files && e.target.files[0];

    const fileExtension = file && file.name.split('.').pop()?.toLowerCase();
    const isValidFile = fileExtension
      ? allowedExtensions.includes(fileExtension)
      : false;

    // checking if file is valid and not bigger than allowed file size
    if (file && isValidFile && file.size <= MAX_FILE_SIZE) {
      setFiles({
        draggedFile: null,
        uploadedFile: file,
        fileToSend: file,
      });
      setUploadError('');
    } else if (!isValidFile) {
      setUploadError(fileExtensionErrorMsg);
    } else {
      setUploadError(fileSizeErrorMsg);
    }
  };

  // file drag and drop functionality
  const onDrop = useCallback(acceptedFiles => {
    const fileExtension =
      acceptedFiles[0] && acceptedFiles[0].name.split('.').pop().toLowerCase();
    const isValidFile = allowedExtensions.includes(fileExtension);

    if (isValidFile && acceptedFiles[0].size <= MAX_FILE_SIZE) {
      setFiles({
        uploadedFile: null,
        draggedFile: acceptedFiles[0],
        fileToSend: acceptedFiles[0],
      });
      setUploadError('');
    } else if (!isValidFile) {
      setUploadError(fileExtensionErrorMsg);
    } else {
      setUploadError(fileSizeErrorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendFileRequest = (formData: FormData, jwtToken: string) => {
    axios
      .post(fileUploadUrl, formData, {
        headers: {
          authorization: `${jwtToken}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        setIsLoading(false);
        if (response.statusText === 'OK') {
          setSuccessMsg(t('Your file is uploaded'));
        } else {
          setUploadError(t('Unable to upload file. Please try again later.'));
        }
      })
      .catch(er => {
        setIsLoading(false);
        /*
          handling large files in aws
          When the frontend getting timeout error actually file is sent, temporary fix not to show error message for the user
        */
        if (er.response?.status === 504) {
          setInfoMsg(t('Your file is uploaded'));
        } else {
          setUploadError(t('Unable to upload file. Please try again later.'));
        }
      });
  };

  const handleSubmit = () => {
    if (files.fileToSend !== null) {
      setIsLoading(true);
      const formData = new FormData();

      getSession()
        .then((session: CognitoUserSession) => {
          const reader = new FileReader();
          if (files.fileToSend !== null) {
            reader.readAsDataURL(files.fileToSend);
            return new Promise<CognitoUserSession>(resolve => {
              reader.onloadend = () => {
                if (reader.result !== null) {
                  formData.append('file', new Blob([reader.result]));
                  if (files.fileToSend !== null) {
                    formData.append('fileName', files.fileToSend.name);
                  }
                }
                resolve(session);
              };
            });
          } else {
            return Promise.reject<CognitoUserSession>('No file to send');
          }
        })
        .then((session: CognitoUserSession) => {
          formData.append(
            'clientId',
            session.getAccessToken().payload.client_id
          );
          formData.append('clientEmail', session.getIdToken().payload.email);
          formData.append('template', template);
          formData.append('uploadType', uploadType);
          formData.append(
            'uploadPeriod',
            `${format(startDate, DATE_FORMAT)} - ${format(
              endDate,
              DATE_FORMAT
            )}`
          );

          const jwtToken = session.getAccessToken().getJwtToken();

          sendFileRequest(formData, jwtToken);
        })
        .catch(() => {
          // User is logged out.
        });
    } else {
      setUploadError(pleaseAttachFile);
    }

    setFiles(initialFilesState);
  };

  return {
    uploadError,
    setUploadError,
    files,
    onChange,
    onDrop,
    handleSubmit,
    clearPrevState,
    isLoading,
    successMsg,
    DATE_FORMAT,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    uploadTypes,
    uploadType,
    setUploadType,
    infoMsg,
  };
};
