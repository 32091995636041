import { FC } from 'react';
import Logo from './Logo';
import MainMenu from './MainMenu';
import UserInfoNav from './userInfoNav/UserInfoNav';
import './Header.scss';

const Header: FC = () => {
  return (
    <header className="navbar navbar-primary header" role="navigation">
      <Logo />
      <nav className="collapse navbar-collapse" aria-label="Menu">
        <MainMenu />
        <UserInfoNav />
      </nav>
    </header>
  );
};

export default Header;
