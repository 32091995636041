import { FC, useEffect, useRef, useState } from 'react';
import './Indicators.scss';
const Indicators: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | undefined>(0);
  const [showingIFrame, setShowingIFrame] = useState(false);
  const WAIT_FOR_IFRAME_SHOW_IN_MS = 500;

  useEffect(() => {
    setHeight(ref?.current?.offsetHeight);
    setTimeout(() => {
      setShowingIFrame(true);
    }, WAIT_FOR_IFRAME_SHOW_IN_MS);
  }, []);

  useEffect(() => {
    console.info('Showing iFrame?', showingIFrame);
  }, [showingIFrame]);
  return (
    <div className="container d-flex flex-column mb-0" data-testid="parent-el">
      <div ref={ref} className="iframe-wrapper">
        {showingIFrame && (
          <iframe
            id="iframe-id"
            title="Indicators that are used in calculating CO2 emissions"
            src={`${process.env.REACT_APP_DOMAIN}/skarpliance/definition/7652/show?&o_=tnh`}
            referrerPolicy="no-referrer"
            style={{ height, width: '100%' }}
          />
        )}
      </div>
    </div>
  );
};
export default Indicators;
