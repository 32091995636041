import { flagIcons } from 'i18n/config';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.scss';
import { FC } from 'react';

export const LanguageSwitcher: FC<{ isTitleVisible?: boolean }> = ({
  isTitleVisible,
}) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const languageButtons = Object.keys(flagIcons).map((lang, key) => {
    return (
      <button
        id={'language-button-' + lang}
        className={'flag ' + (i18n.language === lang && 'active')}
        onClick={() => changeLanguage(lang)}
        key={key}
        data-testid={'language-button-' + lang}
      >
        <img
          src={flagIcons[lang as keyof typeof flagIcons]}
          title={lang.toUpperCase()}
          alt={lang.toUpperCase()}
        />
      </button>
    );
  });

  return (
    <div className="language-switcher">
      {isTitleVisible && (
        <span className="language-switcher__title">{t('language')}</span>
      )}
      <div className="language-switcher__buttons">{languageButtons}</div>
    </div>
  );
};

LanguageSwitcher.defaultProps = {
  isTitleVisible: true,
};
