import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormError, { errorClass } from 'components/form/formError';
import { ErrorMessage } from '@hookform/error-message';
import {
  AccountContext,
  ILoginErrorReturn,
  LOGIN_ERRORS,
} from 'auth/accountProvider';
import { Link, useNavigate } from 'react-router-dom';
import Checkbox from 'components/form/inputs/Checkbox';
import { useValidation } from 'components/form/useValidation';
import { useLogin } from './useLogin';
import InfoAlert from 'components/alert/InfoAlert';
import { LanguageSwitcher } from 'components/header/languageSwitcher/LanguageSwitcher';
import './Login.scss';
import SendVerificationCode from './SendVerificationCode';

type FormValues = {
  username: string;
  password: string;
};

const Login: FC = () => {
  const { t } = useTranslation();
  const { authenticate, needsMfaSmsCode } = useContext(AccountContext);
  const rules = useValidation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const [cognitoError, setCognitoError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const {
    handleSaveUsername,
    savedUsername,
    shouldSaveUsername,
    setShouldSaveUsername,
    locationState,
  } = useLogin();

  /*
   * remove loading spinner if user go Back (using Back button)
   * from SendVerificationCode view to Login
   */
  useEffect(() => {
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsMfaSmsCode]);

  const handleLogin: SubmitHandler<FormValues> = form => {
    setIsLoading(true);
    const username = form.username.toLowerCase();

    authenticate(username, form.password)
      .then(() => {
        setIsLoading(false);
        handleSaveUsername(username);
      })
      .catch((error: ILoginErrorReturn) => {
        if (error.type === LOGIN_ERRORS.NEW_PASSWORD_REQUIRED) {
          const state = {
            resetUsername: error.user?.getUsername(),
            forceReset: true,
            user: error.user,
          };
          navigate('/reset-password', { state: state });
        } else if (!!error.message) {
          setIsLoading(false);
          setCognitoError(
            t(error.message, t('Login unknown untranslated error'))
          );
        }
      });
  };

  return (
    <>
      {locationState?.isResetPassword && (
        <InfoAlert text={t('new password explanation')} />
      )}

      {locationState?.isSendCodeUsernameError && (
        <InfoAlert text={t('username not found explanation')} />
      )}

      {locationState?.isSessionTimeout && !needsMfaSmsCode && (
        <InfoAlert text={t('session timeout explanation')} />
      )}

      {needsMfaSmsCode ? (
        <SendVerificationCode />
      ) : (
        <form onSubmit={handleSubmit(handleLogin)}>
          <fieldset disabled={isLoading}>
            <div
              className={`form-group required ${errorClass(
                errors,
                'username'
              )}`}
            >
              <label htmlFor="input-username" className="control-label">
                {t('username')}
              </label>
              <input
                id="input-username"
                data-testid="input-username"
                type="text"
                placeholder={t('email placeholder')}
                className="form-control"
                defaultValue={savedUsername}
                {...register('username', { ...rules.required, ...rules.email })}
              />
              <ErrorMessage {...{ errors }} name="username" as={FormError} />
            </div>

            <div
              className={`form-group required ${errorClass(
                errors,
                'password'
              )}`}
            >
              <label htmlFor="input-password" className="control-label">
                {t('password')}
              </label>
              <div className="password-toggle-container">
                <input
                  id="input-password"
                  data-testid="input-password"
                  type={isPasswordVisible ? 'text' : 'password'}
                  placeholder={t('password')}
                  className="form-control password-toggle"
                  {...register('password', rules.required)}
                />
                <button
                  id="password-toggler"
                  data-testid="password-toggler"
                  type="button"
                  className="password-toggler"
                  onClick={() => {
                    setIsPasswordVisible(!isPasswordVisible);
                  }}
                />
              </div>
              <ErrorMessage {...{ errors }} name="password" as={FormError} />
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-6">
                  <div className="">
                    <Link to="/send-code" id="forgot-password">
                      {t('forgotPassword')}
                    </Link>
                  </div>
                </div>
                <div className="col-6">
                  <Checkbox
                    id="remember-username"
                    dataTestId="remember-username"
                    label={t('rememberUsername')}
                    checked={shouldSaveUsername}
                    onChange={() => {
                      setShouldSaveUsername(!shouldSaveUsername);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              {!isLoading && (
                <button
                  id="submit-btn"
                  type="submit"
                  className="btn btn-primary btn-login"
                  data-testid="submit-btn"
                >
                  {t('login')}
                </button>
              )}

              {isLoading && (
                <div className="d-flex justify-content-center">
                  <span className="spinner spinner-default-green"></span>
                </div>
              )}

              {!isLoading && cognitoError && (
                <FormError>{cognitoError}</FormError>
              )}
            </div>
          </fieldset>
        </form>
      )}

      <div className="mt-48 mb-16">
        <LanguageSwitcher isTitleVisible={false} />
      </div>
    </>
  );
};

export default Login;
