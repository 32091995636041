import { useContext, useEffect, useState } from 'react';
import { AccountContext } from 'auth/accountProvider';
import { useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import Popup from 'components/popup/Popup';
import { useTranslation } from 'react-i18next';

export const SessionTimeout = () => {
  const SECOND_IN_MS = 1000;
  const MINUTE_IN_MS = SECOND_IN_MS * 60;
  const SESSION_TIMEOUT_MINUTES = 30;
  const PROMPT_TIMEOUT_SECONDS = 59;
  const SESSION_TIMEOUT = MINUTE_IN_MS * SESSION_TIMEOUT_MINUTES;
  const PROMPT_TIMEOUT = SECOND_IN_MS * PROMPT_TIMEOUT_SECONDS;

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [remainingTime, setRemainingTime] = useState<number>(0);

  const navigate = useNavigate();

  const accountContext = useContext(AccountContext);
  const { isAuthenticated, logout } = accountContext;

  useEffect(() => {
    const remainingTimeout = setInterval(() => {
      setRemainingTime(Math.ceil(getRemainingTime() / SECOND_IN_MS));
    }, SECOND_IN_MS);

    return () => clearInterval(remainingTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnIdle = () => {
    if (!isAuthenticated) {
      return;
    }

    setIsModalOpen(false);

    navigate('/login', {
      state: { isSessionTimeout: true },
    });

    reset();
    logout();
  };

  const handleOnPrompt = () => {
    if (!isAuthenticated) {
      return;
    }

    setIsModalOpen(true);
  };

  const { getRemainingTime, reset } = useIdleTimer({
    timeout: SESSION_TIMEOUT,
    promptTimeout: PROMPT_TIMEOUT,
    onIdle: handleOnIdle,
    onPrompt: handleOnPrompt,
    debounce: 1000,
    crossTab: true,
  });

  const handleModalClose = () => {
    reset();
    setIsModalOpen(false);
  };

  return (
    <>
      <Popup
        title={t('session timeout')}
        isPopupOpen={isModalOpen}
        handleAction={handleOnIdle}
        cancelAction={handleModalClose}
        handleClose={handleModalClose}
        leftButtonTitle={t('End session now')}
      >
        <span>
          {t('session timeout prompt')}: <b>{remainingTime}</b>
        </span>
      </Popup>
    </>
  );
};
