import { Dispatch, FC, SetStateAction } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

interface IProps {
  dateFormat: string;
  startDate: Date | undefined;
  setStartDate: Dispatch<SetStateAction<Date>>;
  endDate: Date | undefined;
  setEndDate: Dispatch<SetStateAction<Date>>;
  isLoading: boolean;
}

export const UploadFormDatePicker: FC<IProps> = ({
  dateFormat,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isLoading,
}) => {
  const { i18n, t } = useTranslation();

  return (
    <>
      <div className="upload__datepicker">
        <div className="form-group upload__date">
          <label htmlFor="datepicker-from">{t('Start date')}</label>
          <DatePicker
            id="upload-datepicker-from"
            dateFormat={dateFormat}
            locale={i18n?.language}
            selected={startDate}
            onChange={date => setStartDate(date || new Date())}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            disabled={isLoading}
            showMonthYearPicker
            maxDate={endDate || new Date()}
          />
        </div>

        <div className="form-group upload__date">
          <label htmlFor="datepicker-to">{t('End date')}</label>
          <DatePicker
            id="upload-datepicker-to"
            dateFormat={dateFormat}
            locale={i18n?.language}
            selected={endDate}
            onChange={date => setEndDate(date || new Date())}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            disabled={isLoading}
            showMonthYearPicker
            maxDate={new Date()}
            minDate={startDate || null}
          />
        </div>
      </div>
    </>
  );
};
