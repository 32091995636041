export const urlTemplateFuel = `${process.env.REACT_APP_DOMAIN}/backend/templates/fuel_data_upload`;
export const urlTemplateTransport = `${process.env.REACT_APP_DOMAIN}/backend/templates/transport_data_upload`;
export const urlTemplateCPI = `${process.env.REACT_APP_DOMAIN}/backend/templates/cpi_data_upload`;

export const fileNameFuel = 'UploadTemplate Brandstofgegevens.xlsx';
export const fileNameTransport = 'UploadTemplate Transportbeheergegevens.xlsx';
export const fileNameCPI = 'UploadTemplate Uitstootindicatoren.xlsx';

export const allowedExtensions = ['csv', 'xlsx'];
export const MAX_FILE_SIZE = 4708106; // bytes or 4.49MB
export const fileUploadUrl = `${process.env.REACT_APP_DOMAIN}/backend/data`;
