import { FC, ReactNode, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DownloadLink } from 'pages/download/DownloadLink';
import { AccountContext, ROLES, RoleType } from 'auth/accountProvider';

interface MenuItem {
  id: string;
  title: string;
  route: string;
  roles: RoleType[];
  linkComponent?: ReactNode;
}

const menuItems: MenuItem[] = [
  {
    id: 'overview',
    title: 'overview',
    route: '/',
    roles: [], // Visible for all users
  },
  {
    id: 'CO2reportinge',
    title: 'CO2reporting',
    route: '/reports',
    roles: [],
  },
  {
    id: 'uploadAndTemplates',
    title: 'uploadAndTemplates',
    route: '/upload',
    roles: [ROLES.UPLOAD], // Visible only for Upload user
  },
  {
    id: 'downloadReport',
    title: '',
    route: '',
    roles: [ROLES.UPLOAD, ROLES.REPORT], // Visible for Upload or Report user
    linkComponent: <DownloadLink />,
  },
];

const MainMenu: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { hasRole } = useContext(AccountContext);

  return (
    <>
      <ul className="nav navbar-nav nav-tabs first-level" role="menubar">
        {menuItems.map(item => {
          const className = location.pathname === item.route ? 'active' : '';

          return hasRole(item.roles) ? (
            <li key={item.id} className={className}>
              {item.linkComponent ?? (
                <Link
                  to={item.route}
                  className="nav-item fl-menu-item"
                  role="menuitem"
                >
                  {t(item.title)}
                </Link>
              )}
            </li>
          ) : null;
        })}
      </ul>
    </>
  );
};

export default MainMenu;
