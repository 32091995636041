import { FC, useContext, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useValidation } from 'components/form/useValidation';
import FormError, { errorClass } from 'components/form/formError';
import { ErrorMessage } from '@hookform/error-message';
import { AccountContext } from 'auth/accountProvider';
import { useTranslation } from 'react-i18next';
import InfoAlert from 'components/alert/InfoAlert';

const SendCode: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sendCode } = useContext(AccountContext);
  const rules = useValidation();

  const location = useLocation();
  const locationState = location.state as {
    resetUsername: string;
  };

  const resetUsername = locationState?.resetUsername ?? '';

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [cognitoError, setCognitoError] = useState<string>('');

  const handleSendCode = (form: any) => {
    const username = form.username.toLowerCase();
    sendCode(username)
      .then(() => {
        navigate('/reset-password', {
          state: { resetUsername: username },
        });
      })
      .catch((error: any) => {
        if (error === 'Username/client id combination not found.') {
          navigate('/login', {
            state: { isSendCodeUsernameError: true },
          });
        } else {
          setCognitoError(
            t(error, t('Send request unknown untranslated error'))
          );
        }
      });
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(handleSendCode)}>
      <InfoAlert text={t('send request explanation')} />
      <div className={'form-group required' + errorClass(errors, 'username')}>
        <label htmlFor="input-username" className="control-label">
          {t('username')}
        </label>
        <input
          id="input-username"
          data-testid="input-username"
          type="text"
          placeholder={t('email placeholder')}
          className="form-control"
          {...register('username', { ...rules.required, ...rules.email })}
          defaultValue={resetUsername}
        />
        <ErrorMessage {...{ errors }} name="username" as={FormError} />
      </div>

      <div className="form-group">
        <button
          id="submit-send-code"
          data-testid="submit-send-code"
          type="submit"
          className="btn btn-primary btn-login"
        >
          {t('send request')}
        </button>
        {cognitoError && <FormError>{cognitoError}</FormError>}
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col-12">
            <Link to="/login" className="btn btn-link" id="back-button">
              <span className="vismaicon vismaicon-move-left-circle" />
              {t('back')}
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SendCode;
