import { FC, MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface UploadRowProps {
  title: string;
  iconComponent: ReactNode;
  handleDownload: (e: MouseEvent<HTMLButtonElement>) => void;
  handleUpload: (e: MouseEvent<HTMLButtonElement>) => void;
  isLoading: boolean;
  idKey?: string;
}

const UploadRow: FC<UploadRowProps> = ({
  title,
  iconComponent,
  handleDownload,
  handleUpload,
  isLoading,
  idKey,
}) => {
  const { t } = useTranslation();

  return (
    <div className="uploads__row d-flex justify-content-between mb-16">
      <div className="uploads__column d-flex align-items-center">
        <span className="uploads__icon d-flex justify-content-center align-items-center">
          {iconComponent}
        </span>
        <strong>{title}</strong>
      </div>

      <div className="uploads__column d-flex justify-content-center align-items-center"></div>

      <div className="uploads__column d-flex justify-content-center align-items-center">
        <button
          id={`download-btn-${idKey}`}
          type="button"
          className="btn btn-default"
          onClick={handleDownload}
          data-testid={`download-btn-${idKey}`}
          disabled={isLoading}
        >
          {t('downloadTemplate')}
        </button>
        <button
          id={`upload-btn-${idKey}`}
          type="button"
          className="btn btn-primary"
          onClick={handleUpload}
          data-testid={`upload-btn-${idKey}`}
        >
          {t('uploadInformation')}
        </button>
      </div>
    </div>
  );
};

export default UploadRow;

UploadRow.defaultProps = {
  idKey: '',
};
