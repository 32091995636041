import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  AccountContext,
  ILoginErrorReturn,
  LOGIN_ERRORS,
} from 'auth/accountProvider';
import { useLogin } from './useLogin';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import InfoAlert from 'components/alert/InfoAlert';
import FormError from 'components/form/formError';

type FormValues = {
  verificationCode: string;
};

const SendVerificationCode: FC = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cognitoError, setCognitoError] = useState<string>('');

  const { loginState, logout, checkUserInfo, setIsAuthenticated } =
    useContext(AccountContext);

  const { register, handleSubmit } = useForm<FormValues>();

  const { handleRedirect } = useLogin();

  const handleSendCode: SubmitHandler<FormValues> = form => {
    setIsLoading(true);
    const code = form.verificationCode;
    const user = loginState?.cognitoUser;

    /*
     * allow to log-in without 2fa if in aws MFA is set to inactive,
     * also some config in accountProvider.tsx login() function
     */
    if (!loginState.isMfaStarted) {
      setIsLoading(false);
      handleRedirect();
      return null;
    }

    return new Promise((resolve, reject) => {
      user?.sendMFACode(code, {
        onSuccess: (session: CognitoUserSession) => {
          checkUserInfo(session, resolve, reject);
          setIsAuthenticated(true);
        },
        onFailure: (error: any) => {
          setIsAuthenticated(false);
          reject({
            message: error.message,
            type: LOGIN_ERRORS.MFA_SMS_REQUIRED,
          });
        },
      });
    })
      .then(() => {
        setIsLoading(false);
        handleRedirect();
      })
      .catch((err: ILoginErrorReturn) => {
        setIsLoading(false);
        setIsAuthenticated(false);
        if (err.type === LOGIN_ERRORS.MFA_SMS_REQUIRED) {
          err.message && setCognitoError(t(err.message));
        } else if (!!err.message) {
          setCognitoError(
            t(err.message, t('Login unknown untranslated error'))
          );
        }
      });
  };

  const handleBack = () => {
    logout();
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(handleSendCode)}>
      <fieldset disabled={isLoading}>
        {/* Info message appears if in aws MFA is set to inactive */}
        {!loginState.isMfaStarted && (
          <InfoAlert text="MFA is inactive. You can login without verification code." />
        )}
        <p>
          {t('We sent a verification code to your mobile device with number:')}
          <b> {loginState?.phoneNr}</b>
        </p>
        <div className="form-group required">
          <label htmlFor="input-username" className="control-label">
            {t('Enter verification code')}
          </label>
          <input
            id="verification-code-input"
            data-testid="verification-code-input"
            type="number"
            className="form-control"
            {...register('verificationCode')}
          />
        </div>

        <div className="form-group">
          <button
            id="submit-send-code"
            type="submit"
            className="btn btn-primary btn-login"
            data-testid="submit-send-code"
          >
            {t('login')}
          </button>
        </div>
        {isLoading && (
          <div
            className="d-flex justify-content-center"
            data-testid="loading-spinner"
          >
            <span className="spinner spinner-default-green"></span>
          </div>
        )}
        {!isLoading && cognitoError && <FormError>{cognitoError}</FormError>}

        <div className="form-group">
          <div className="row mt-16">
            <div className="col-12">
              <button
                className="btn btn-link"
                id="send-code-back-button"
                onClick={handleBack}
                data-testid="send-code-back-button"
              >
                <span className="vismaicon vismaicon-move-left-circle" />
                {t('back')}
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  );
};

export default SendVerificationCode;
