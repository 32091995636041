import { FC, ReactNode } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';

const FormError: FC<{ children?: ReactNode }> = ({ children }) => (
  <div className="invalid-feedback mt-8" style={{ color: '#CC0000' }}>
    {children}
  </div>
);

export default FormError;

export const errorClass = (errors: FieldErrors, name: string) => {
  return errors[name] ? 'has-error' : '';
};
