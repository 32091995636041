import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import './Popup.scss';

interface Props {
  title: string;
  isPopupOpen: boolean;
  handleClose: () => void;
  handleAction: () => void;
  cancelAction: () => void;
  leftButtonTitle: string;
  isModalDisabled?: boolean;
  children: ReactNode;
  rightButtonTitle?: string;
}

const Popup: FC<Props> = ({
  title,
  isPopupOpen,
  handleClose,
  handleAction,
  cancelAction,
  leftButtonTitle,
  isModalDisabled,
  children,
  rightButtonTitle,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`modal ${isPopupOpen ? 'fade in' : ''}`}
      data-testid="modal"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              id="close-btn"
              type="button"
              className="close"
              aria-label="Close"
              onClick={handleClose}
              data-testid="close-btn"
            />
            <h4 className="modal-title modal__title">{title}</h4>
          </div>
          <div className="modal-body d-flex justify-content-between align-items-center">
            {children}
          </div>
          <div className="modal-footer d-flex justify-content-between">
            {leftButtonTitle && (
              <button
                id="submit-action"
                disabled={isModalDisabled}
                type="button"
                className="btn btn-primary modal__button"
                onClick={handleAction}
                data-testid="submit-action"
              >
                {leftButtonTitle}
              </button>
            )}
            <button
              id="cancel-action"
              disabled={isModalDisabled}
              type="button"
              className="btn btn-default modal__button"
              onClick={cancelAction}
            >
              {rightButtonTitle || t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
