import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const TextInstructions: FC = () => {
  const { t } = useTranslation();

  const mandatoryFields = [
    'uploadInstructions.mandatoryFields.field1',
    'uploadInstructions.mandatoryFields.field2',
    'uploadInstructions.mandatoryFields.field3',
    'uploadInstructions.mandatoryFields.field4',
    'uploadInstructions.mandatoryFields.field5',
  ];

  const conditionalFields = [
    { field: 'uploadInstructions.conditionalFields.field1', explanation: null },
    { field: 'uploadInstructions.conditionalFields.field2', explanation: null },
    {
      field: 'uploadInstructions.conditionalFields.field3.field',
      explanation: 'uploadInstructions.conditionalFields.field3.explanation',
    },
    {
      field: 'uploadInstructions.conditionalFields.field4',
      explanation: null,
    },
    {
      field: 'uploadInstructions.conditionalFields.field5',
      explanation: null,
    },
    {
      field: 'uploadInstructions.conditionalFields.field6',
      explanation: null,
    },
    {
      field: 'uploadInstructions.conditionalFields.field7.field',
      explanation: 'uploadInstructions.conditionalFields.field7.explanation',
      explanation2: 'uploadInstructions.conditionalFields.field7.explanation2',
    },
    {
      field: 'uploadInstructions.conditionalFields.field8',
      explanation: null,
    },
    {
      field: 'uploadInstructions.conditionalFields.field9',
      explanation: null,
    },
    {
      field: 'uploadInstructions.conditionalFields.field10',
      explanation: null,
    },
  ];

  const requiredBrandstofFields = [
    'uploadInstructions.requiredBrandstofFields.field1',
    'uploadInstructions.requiredBrandstofFields.field2',
    'uploadInstructions.requiredBrandstofFields.field3',
    'uploadInstructions.requiredBrandstofFields.field4',
    'uploadInstructions.requiredBrandstofFields.field5',
  ];

  const tabsList = [
    'uploadInstructions.tabsList.listItem1',
    'uploadInstructions.tabsList.listItem2',
    'uploadInstructions.tabsList.listItem3',
    'uploadInstructions.tabsList.listItem4',
    'uploadInstructions.tabsList.listItem5',
    'uploadInstructions.tabsList.listItem6',
    'uploadInstructions.tabsList.listItem7',
  ];

  const cpiFields = [
    'uploadInstructions.cpiFields.field1',
    'uploadInstructions.cpiFields.field2',
    'uploadInstructions.cpiFields.field3',
    'uploadInstructions.cpiFields.field4',
    'uploadInstructions.cpiFields.field5',
  ];

  return (
    <div className="uploads__instructions mr-16">
      <h3>{t('uploadInstructionsTitle')}</h3>
      <p>{t('uploadInstructions.part1')}</p>
      <ol>
        {tabsList.map(tab => (
          <li className="m-8" key={`tabs-key-${tab}`}>
            <Trans i18nKey={tab} />
          </li>
        ))}
      </ol>

      {/* explanation of upload sheet Ritten-administratie: */}
      <h4 className="mb-12 mt-32">
        <span className="text-underline">
          <Trans i18nKey="uploadInstructions.part2" />
        </span>
      </h4>

      <p className="mb-12">{t('uploadInstructions.part3')}</p>
      <p className="mb-12">{t('uploadInstructions.part4')}</p>
      <p className="mb-12">
        <Trans
          i18nKey="uploadInstructions.part5"
          components={{
            styled: <span className="text-underline" />,
          }}
        />
      </p>

      {mandatoryFields.map(field => (
        <p className="mb-12" key={`key-${field}`}>
          <Trans i18nKey={field} />
        </p>
      ))}
      <p>{t('uploadInstructions.part6')}</p>

      <p className="mt-32 mb-12">
        <Trans
          i18nKey="uploadInstructions.part7"
          components={{ styled: <span className="text-underline" /> }}
        />
      </p>

      {conditionalFields.map(conField => (
        <React.Fragment key={`key-${conField.field}`}>
          <p className="mb-12">
            <Trans i18nKey={conField.field} />
          </p>
          {conField.explanation && (
            <p className="mb-12">{t(conField.explanation)}</p>
          )}
          {conField.explanation2 && (
            <p className="mb-12">{t(conField.explanation2)}</p>
          )}
        </React.Fragment>
      ))}

      {/* Explanation upload sheet Brandstof administratie: */}
      <h4 className="mb-12 mt-32">
        <span className="text-underline">
          <Trans i18nKey="uploadInstructions.part8" />
        </span>
      </h4>
      <p className="mb-12">{t('uploadInstructions.part9')}</p>
      <p className="mb-12">{t('uploadInstructions.part10')}</p>
      <p>
        <Trans i18nKey="uploadInstructions.part11" />
      </p>

      {requiredBrandstofFields.map(field => (
        <p className="mb-12" key={`key-${field}`}>
          <Trans i18nKey={field} />
        </p>
      ))}

      <p className="mt-24 mb-12">
        <Trans i18nKey="uploadInstructions.part12" />
      </p>
      <p className="mb-12">{t('uploadInstructions.part13')}</p>
      <p className="mb-12">{t('uploadInstructions.part14')}</p>

      {/* Explanation upload sheet Emissie  indicator_administratie: */}
      <h4 className="mb-12 mt-32">
        <span className="text-underline">
          <Trans i18nKey="uploadInstructions.part15" />
        </span>
      </h4>
      <p className="mb-12">{t('uploadInstructions.part16')}</p>
      {cpiFields.map(field => (
        <p className="mb-12" key={`key-${field}`}>
          <Trans i18nKey={field} />
        </p>
      ))}
    </div>
  );
};

export default TextInstructions;
