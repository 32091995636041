import axios from 'axios';
import { FC, useContext, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { AccountContext } from 'auth/accountProvider';
import UploadRow from './UploadRow';
import UploadForm from './UploadForm';
import Card from 'components/card/Card';
import Popup from 'components/popup/Popup';
import { ReactComponent as CarIcon } from 'assets/images/icon-car.svg';
import {
  fileNameCPI,
  fileNameFuel,
  fileNameTransport,
  urlTemplateCPI,
  urlTemplateFuel,
  urlTemplateTransport,
} from './constants';

import './Uploads.scss';
import TextInstructions from './TextInstructions';
import { downloadExcel } from './utils';

const Uploads: FC = () => {
  const { t } = useTranslation();
  const { getSession } = useContext(AccountContext);

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [popUpTitle, setPopupTitle] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [template, setTemplate] = useState<string>('');
  const [isModalDisabled, setIsModalDisabled] = useState<boolean>(false);

  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const downloadTemplateErrMsg = t(
    'Unable to download template. Please try again later.'
  );

  // opens upload popup and set popup title and template type for upload post request
  const openUploadPopup = (e: MouseEvent<HTMLButtonElement>) => {
    setIsPopupOpen(true);
    setError('');
    const target = e.target as HTMLButtonElement;

    if (target.id === 'upload-btn-1') {
      setTemplate('fuel_data_upload');
      setPopupTitle(t('Fuel data'));
    }
    if (target.id === 'upload-btn-2') {
      setTemplate('transport_data_upload');
      setPopupTitle(t('Transport Management Data'));
    }
    if (target.id === 'upload-btn-3') {
      setTemplate('cpi_data_upload');
      setPopupTitle(t('Emission indicators'));
    }
  };

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const handleUpload = () => {
    setIsSubmitted(true);
  };

  const getTemplate = (jwtToken: string, fileName: string, url: string) => {
    axios
      .get(url, {
        headers: {
          authorization: `${jwtToken}`,
        },
      })
      .then(response => {
        if (response.data) {
          downloadExcel(response.data, fileName);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setError(downloadTemplateErrMsg);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setError(downloadTemplateErrMsg);
      });
  };

  const handleDownload = (e: MouseEvent<HTMLButtonElement>) => {
    setError('');
    setIsLoading(true);
    const target = e.target as HTMLButtonElement;

    getSession()
      .then((session: CognitoUserSession) => {
        const jwtToken = session.getAccessToken().getJwtToken();

        if (target.id === 'download-btn-1') {
          getTemplate(jwtToken, fileNameFuel, urlTemplateFuel);
        }

        if (target.id === 'download-btn-2') {
          getTemplate(jwtToken, fileNameTransport, urlTemplateTransport);
        }
        if (target.id === 'download-btn-3') {
          getTemplate(jwtToken, fileNameCPI, urlTemplateCPI);
        }
      })
      .catch();
  };

  return (
    <div className="container uploads">
      <h1>{t('uploadPageTitle')}</h1>
      <div className="row mt-32">
        <div className="col-sm-12 col-md-12">
          <Card customClass="rows-container d-flex">
            <div className="w-100">
              <UploadRow
                title={t('Fuel data')}
                iconComponent={<CarIcon />}
                handleDownload={handleDownload}
                handleUpload={openUploadPopup}
                idKey="1"
                isLoading={isLoading}
              />

              <UploadRow
                title={t('Transport Management Data')}
                iconComponent={
                  <span className="vismaicon vismaicon-document_production" />
                }
                handleDownload={handleDownload}
                handleUpload={openUploadPopup}
                idKey="2"
                isLoading={isLoading}
              />

              <UploadRow
                title={t('Emission indicators')}
                iconComponent={
                  <span className="vismaicon vismaicon-document_production" />
                }
                handleDownload={handleDownload}
                handleUpload={openUploadPopup}
                idKey="3"
                isLoading={isLoading}
              />

              <Popup
                title={`${t('Upload information')} - ${popUpTitle}`}
                isPopupOpen={isPopupOpen}
                handleClose={handleClose}
                handleAction={handleUpload}
                cancelAction={handleClose}
                leftButtonTitle={t('Upload')}
                isModalDisabled={isModalDisabled}
                rightButtonTitle={t('back')}
              >
                <UploadForm
                  {...{
                    isSubmitted,
                    setIsSubmitted,
                    setIsModalDisabled,
                    isPopupOpen,
                    template,
                  }}
                />
              </Popup>

              {error && (
                <div
                  role="alert"
                  className="alert alert-danger alert-sm alert-dismissible"
                >
                  {error}
                </div>
              )}
            </div>
          </Card>
          <Card customClass="mt-16">
            <TextInstructions />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Uploads;
