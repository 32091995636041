import { useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useLocation, useNavigate } from 'react-router-dom';

export const useLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as {
    referer: string;
    isResetPassword: boolean;
    isSendCodeUsernameError: boolean;
    isSessionTimeout: boolean;
  };

  const [savedUsername, setSavedUsername] = useLocalStorage(
    'savedUsername',
    ''
  );

  const [shouldSaveUsername, setShouldSaveUsername] = useState<boolean>(
    !!savedUsername
  );

  const handleSaveUsername = (username: string) => {
    if (shouldSaveUsername) {
      setSavedUsername(username);
    } else {
      setSavedUsername('');
    }
  };

  const handleRedirect = () => {
    if (locationState?.referer && locationState.referer !== '/login') {
      navigate(locationState.referer);
    } else {
      navigate('/');
    }
  };

  return {
    handleSaveUsername,
    savedUsername,
    shouldSaveUsername,
    setShouldSaveUsername,
    handleRedirect,
    locationState,
  };
};
