import { FC, useState } from 'react';

interface CheckboxProps {
  id: string;
  checked?: boolean;
  onChange?: () => void;
  label: string;
  dataTestId?: string;
}

const Checkbox: FC<CheckboxProps> = ({
  id,
  checked,
  onChange,
  label,
  dataTestId,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  return (
    <div className="checkbox">
      <input
        type="checkbox"
        id={id}
        onChange={() => {
          setIsChecked(!isChecked);
          onChange && onChange();
        }}
        checked={isChecked}
        data-testid={dataTestId}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

Checkbox.defaultProps = {
  checked: false,
};

export default Checkbox;
