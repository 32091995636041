import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { AccountContext, ROLES } from 'auth/accountProvider';
import Card from 'components/card/Card';
import { ReactComponent as Earth } from 'assets/images/earth-imgage.svg';
import { ReactComponent as ArrowRight } from 'assets/images/icon-arrow-right.svg';
import './Overview.scss';

const Overview: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { userInfo, hasRole } = useContext(AccountContext);

  const handleOpenReports = () => {
    navigate('reports');
  };

  const navigateToPage = () => {
    navigate('upload');
  };

  const arrowWithBg = (
    <span className="caret-bg">
      <span className="caret" />
    </span>
  );

  return (
    <div className="container overview">
      <div className="row mt-32">
        <div className="col-sm-12 col-md-6">
          <Card customClass="overview__card1">
            <span className="overview__greeting">
              {t('hello')},{' '}
              <span className="overview__name">{`${userInfo.givenName} ${userInfo.familyName}`}</span>
            </span>
          </Card>
        </div>
        <div className="col-sm-12 col-md-6">
          <Card customClass="overview__card2">
            <Earth />
            <button
              id="open-reports"
              type="button"
              className="btn btn-primary"
              onClick={handleOpenReports}
              data-testid="open-reports"
            >
              {t('btnLabelOpenReports')}
            </button>
          </Card>
        </div>
      </div>

      <div className="row mt-32">
        <div className="col-sm-12 col-md-4">
          <Card customClass="overview__card3">
            <h2 className="modal-title">{t('knowledgeBaseTitle')}</h2>

            <Link
              id="button-knowledge-base1"
              to="/indicators"
              className="light-button btn"
            >
              {t('buttonKnowledgeBase1')}
              {arrowWithBg}
            </Link>
            <a
              id="button-knowledge-base2"
              href="https://www.co2emissiefactoren.nl/lijst-emissiefactoren/"
              target="_blank"
              rel="noopener noreferrer"
              className="light-button btn"
            >
              {t('buttonKnowledgeBase2')}
              {arrowWithBg}
            </a>
          </Card>
        </div>

        {hasRole(ROLES.UPLOAD) && (
          <div className="col-sm-12 col-md-4">
            <Card customClass="overview__card4 d-flex flex-column">
              <h2 className="modal-title">{t('uploadTemplatesTitle')}</h2>
              <p>{t('uploadTemplatesIntroduction')}</p>

              <button
                id="open-uploads"
                type="button"
                className="button-with-icon btn btn-lg btn-default mt-auto"
                onClick={navigateToPage}
                data-testid="open-uploads"
              >
                <span className="mr-12">{t('btnLabelUploadTemplates')}</span>
                <ArrowRight />
              </button>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};

export default Overview;
