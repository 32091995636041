import { FC } from 'react';

interface Props {
  text: string;
  customClass?: string;
}

const InfoAlert: FC<Props> = ({ text, customClass = '' }) => {
  return (
    <div className={`alert alert-info ${customClass}`} role="alert">
      {text}
    </div>
  );
};

export default InfoAlert;
