import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import 'components/form/datepicker/datepickerCustom.scss';
import FormError from 'components/form/formError';
import { ErrorMessage } from '@hookform/error-message';
import { useDownloadForm } from 'pages/download/useDownloadForm';
import './DownloadForm.scss';

interface IDownloadForm {
  isSubmitted: boolean;
  setIsSubmitted: Dispatch<SetStateAction<boolean>>;
  setIsModalDisabled: Dispatch<SetStateAction<boolean>>;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  isModalOpen: boolean;
}

const DownloadForm: FC<IDownloadForm> = ({
  isSubmitted,
  setIsSubmitted,
  setIsModalDisabled,
  setIsModalOpen,
  isModalOpen,
}) => {
  const { i18n, t } = useTranslation();

  const {
    resetForm,
    isLoading,
    handleSubmit,
    handleDownload,
    handleDateValidation,
    register,
    errors,
    DATE_FORMAT,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    downloadError,
  } = useDownloadForm(setIsModalOpen);

  useEffect(() => {
    if (!isModalOpen) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  useEffect(() => {
    setIsModalDisabled(isLoading);
  }, [isLoading, setIsModalDisabled]);

  useEffect(() => {
    if (isSubmitted) {
      handleSubmit(handleDownload)();
      setIsSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit, isSubmitted, setIsSubmitted]);

  useEffect(() => {
    if (startDate && endDate) {
      handleDateValidation(startDate, endDate);
    }
  }, [startDate, endDate, handleDateValidation]);

  const ProgressBar = (
    <div className="horizontal-loading horizontal-loading-sm">
      <div className="loading-bar" role="progressbar" />
      <span className="loading-bar-label">{t('downloadProgressLabel')}</span>
    </div>
  );

  return (
    <form
      id="report-download-form"
      className="download"
      onSubmit={handleSubmit(handleDownload)}
    >
      <div className="form-group">
        <label htmlFor="exampleInputEmail4">{t('clientCodeLabel')}</label>
        <input
          id="client-code"
          type="text"
          className="form-control download__client-code"
          placeholder={t('Customer code')}
          maxLength={100}
          {...register('clientCode')}
          disabled={isLoading}
        />
        <ErrorMessage {...{ errors }} name="clientCode" as={FormError} />
      </div>
      <div className="download__datepicker">
        <div className="form-group download__date">
          <label htmlFor="datepicker-from">{t('Start date')}</label>
          <DatePicker
            id="datepicker-from"
            dateFormat={DATE_FORMAT}
            locale={i18n?.language}
            selected={startDate}
            maxDate={endDate}
            disabled={isLoading}
            onChange={date => setStartDate(date)}
            showMonthYearPicker
          />
        </div>
        <div className="form-group download__date">
          <label htmlFor="datepicker-from">{t('End date')}</label>
          <DatePicker
            id="datepicker-from"
            dateFormat={DATE_FORMAT}
            locale={i18n?.language}
            selected={endDate}
            maxDate={new Date()}
            disabled={isLoading}
            onChange={date => setEndDate(date)}
            showMonthYearPicker
          />
        </div>
      </div>
      {downloadError && (
        <div
          role="alert"
          className="alert alert-danger alert-sm alert-dismissible"
        >
          {downloadError}
        </div>
      )}
      {isLoading && ProgressBar}
    </form>
  );
};

export default DownloadForm;
