import { FC } from 'react';
import { NavLink } from 'react-router-dom';

const Logo: FC = () => {
  return (
    <div className="navbar-header">
      <div className="navbar-brand">
        <NavLink
          className="dropdown-toggle logo"
          to="/"
          role="button"
          aria-label="Loginex"
        >
          Visma Loginex
        </NavLink>
      </div>
    </div>
  );
};

export default Logo;
