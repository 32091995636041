import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import nlFlagIcon from 'assets/images/flags/nl.svg';
import enFlagIcon from 'assets/images/flags/en.svg';
import EN from './locales/en/translations.json';
import NL from './locales/nl/translations.json';

const DETECTION_OPTIONS = {
  order: ['localStorage'],
  caches: ['localStorage'],
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: DETECTION_OPTIONS,
    fallbackLng: 'nl',
    resources: {
      nl: {
        translation: NL,
      },
      en: {
        translation: EN,
      },
    },
  });

i18n.languages = ['nl', 'en'];

export const flagIcons = { nl: nlFlagIcon, en: enFlagIcon };

export default i18n;
