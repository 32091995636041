import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFound: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="row error-page align-items-center">
        <div className="error-page-content col-12">
          <h1>{t('Page not found')}</h1>
          <p>
            {t(
              "We're sorry, the page you were looking for could not be found."
            )}
          </p>
          <p className="error-page-home-link">
            <NavLink to="/" className="btn btn-primary">
              {t('Go to homepage')}
            </NavLink>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
