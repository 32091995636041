import { FC, ReactNode } from 'react';
import './Card.scss';

interface CardProps {
  children: ReactNode | string;
  customClass?: string;
}

const Card: FC<CardProps> = ({ children, customClass }) => {
  return (
    <div className={`bg-default shadow-30 rounded-10 card ${customClass}`}>
      {children}
    </div>
  );
};

export default Card;

Card.defaultProps = {
  customClass: '',
};
