import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import InfoAlert from 'components/alert/InfoAlert';
import { useUpload } from './useUpload';
import './UploadForm.scss';
import { UploadFormDatePicker } from 'pages/uploads/UploadFormDatePicker';

interface IUploadFormProps {
  isSubmitted: boolean;
  setIsSubmitted: Dispatch<SetStateAction<boolean>>;
  setIsModalDisabled: Dispatch<SetStateAction<boolean>>;
  isPopupOpen: boolean;
  template: string;
}

const UploadForm: FC<IUploadFormProps> = ({
  isSubmitted,
  setIsSubmitted,
  setIsModalDisabled,
  isPopupOpen,
  template,
}) => {
  const { t } = useTranslation();

  const {
    uploadError,
    files,
    onChange,
    onDrop,
    handleSubmit,
    clearPrevState,
    isLoading,
    successMsg,
    DATE_FORMAT,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    uploadTypes,
    uploadType,
    setUploadType,
    infoMsg,
  } = useUpload(template);

  const { uploadedFile, draggedFile } = files;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    maxFiles: 1,
  });

  useEffect(() => {
    setIsModalDisabled(isLoading);
  }, [isLoading, setIsModalDisabled]);

  useEffect(() => {
    if (isSubmitted) {
      handleSubmit();
      setIsSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted, setIsSubmitted]);

  useEffect(() => {
    if (isPopupOpen) {
      clearPrevState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopupOpen]);

  return (
    <form className="w-100" onSubmit={handleSubmit}>
      <div className="drag-block">
        <div className="drop-area" {...getRootProps()}>
          <input
            id="file-drop-upload"
            className="drop-area-input"
            {...getInputProps()}
            disabled={isLoading}
            data-testid="file-drop-upload"
          />
          <label
            htmlFor="file-drop-upload"
            className="drop-area-upload"
          ></label>
          {isDragActive ? (
            <p>{t('Drop the files here ...')}</p>
          ) : (
            <p>{t('Drag document into this box to upload')}</p>
          )}
        </div>
      </div>
      <p>{draggedFile?.name}</p>

      <div className="divider mt-16 mb-16">
        <div className="divider__border" />
        <span className="divider__content">
          {t('Or find a file on your device')}
        </span>
        <div className="divider__border" />
      </div>

      <div className="upload-block">
        <div className="upload-block__input-wrapper mb-16">
          <div
            className="upload-block__file-custom"
            data-testid="uploaded-file-name"
          >
            {uploadedFile?.name}
          </div>

          <label htmlFor="file-upload" className="upload-block__label">
            <input
              type="file"
              id="file-upload"
              className="upload-block__input"
              onChange={onChange}
              onClick={(event: React.MouseEvent<HTMLInputElement>) => {
                event.currentTarget.value = '';
              }}
              disabled={isLoading}
              data-testid="file-upload-input"
            />
          </label>
          <div
            className={`btn btn-default upload-block__btn ${
              isLoading && 'upload-block__btn--disabled'
            }`}
          >
            {t('Browse')}
          </div>
        </div>
        {uploadError && (
          <div
            role="alert"
            className="alert alert-danger alert-sm alert-dismissible"
          >
            {uploadError}
          </div>
        )}

        {infoMsg && (
          <div
            role="alert"
            className="alert alert-info alert-sm alert-dismissible"
          >
            {infoMsg}
          </div>
        )}
        {successMsg && (
          <div
            role="alert"
            className="alert alert-success alert-sm alert-dismissible"
          >
            {successMsg}
          </div>
        )}
        <InfoAlert
          customClass="upload-block__info-msg alert-sm"
          text={t('A maximum file size of x MB allowed for CSV / XLSX files')}
        />
      </div>

      <h4 className="section-title">{t('Upload period')}</h4>

      <UploadFormDatePicker
        dateFormat={DATE_FORMAT}
        {...{ isLoading, startDate, setStartDate, endDate, setEndDate }}
      />

      <h4 className="section-title">{t('Type of upload')}</h4>

      <div className="upload__type">
        <div>
          <div className="radio">
            <input
              type="radio"
              id="uploadTypeReplace"
              data-testid="uploadTypeReplace"
              checked={uploadType === uploadTypes.REPLACE}
              onChange={() => {
                setUploadType(uploadTypes.REPLACE);
              }}
            />
            <label htmlFor="uploadTypeReplace">{t('uploadTypeReplace')}</label>
          </div>
        </div>

        <div>
          <div className="radio">
            <input
              type="radio"
              id="uploadTypeAdd"
              data-testid="uploadTypeAdd"
              checked={uploadType === uploadTypes.ADD}
              onChange={() => {
                setUploadType(uploadTypes.ADD);
              }}
            />
            <label htmlFor="uploadTypeAdd">{t('uploadTypeAdd')}</label>
          </div>
        </div>
      </div>

      {isLoading && (
        <div
          className="d-flex justify-content-center"
          data-testid="upload-spinner"
        >
          <span className="spinner spinner-default-green"></span>
        </div>
      )}
    </form>
  );
};

export default UploadForm;
