import { FC, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'usehooks-ts';
import { Link } from 'react-router-dom';
import { AccountContext } from 'auth/accountProvider';
import { AppContext } from 'appProvider';
import { LanguageSwitcher } from 'components/header/languageSwitcher/LanguageSwitcher';
import './UserInfoNav.scss';

const UserInfoNav: FC = () => {
  const { t } = useTranslation();
  const { logout, userInfo } = useContext(AccountContext);
  const [isOpen, setIsOpen] = useState(false);

  const navRef = useRef(null);
  const handleClickOutside = () => {
    setIsOpen(false);
  };
  useOnClickOutside(navRef, handleClickOutside);

  const { setIsModalOpen } = useContext(AppContext);
  const handleOpenModal = () => {
    setIsOpen(false);
    setIsModalOpen(true);
  };

  return (
    <ul
      ref={navRef}
      className="nav navbar-nav nav-tabs navbar-right first-level"
    >
      <li
        className={`dropdown user-dropdown ${isOpen ? 'open' : ''}`}
        data-testid="user-dropdown"
      >
        <button
          id="dropdown-btn"
          className="dropdown-toggle button-context"
          data-toggle="dropdown"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          data-testid="dropdown-btn"
        >
          {`${userInfo.givenName} ${userInfo.familyName}`}
          <small className="selectedContext mb-0">
            {userInfo.organization}
          </small>
          <span className="caret-bg">
            <span className="caret" data-test="arrow-down-btn" />
          </span>
        </button>

        <ul className="dropdown-menu company-selection" role="menu">
          <li>
            <div className="d-flex flex-row justify-content-between">
              <span>{t('organization')}</span>
              <span>{userInfo.organization}</span>
            </div>
            <div className="d-flex flex-row justify-content-between">
              <span>{t('location')}</span>
              <span>{userInfo.location}</span>
            </div>
          </li>

          <li className="divider" />

          <li>
            <a href="/#">{t('myProfile')}</a>
            <LanguageSwitcher />
          </li>

          <li className="divider" />

          <li>
            <a
              href="https://support.vismaconnect.nl/tas/public/login/saml"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('supportTicketMuniuItem')}
            </a>
          </li>

          <li>
            <button
              id="open-modal-btn"
              type="button"
              onClick={handleOpenModal}
              className="btn-as-menu-item"
              data-testid="open-modal-btn"
            >
              {t('explanationMeniuItem')}
            </button>
          </li>

          <li className="divider" />

          <li>
            <div className="font-weight-bold d-flex flex-column">
              <span>Loginex 1.1</span>
              <span>Copyright 2023 Visma, UC Group</span>
            </div>

            <div>{t('navLoginexInfo')}</div>
          </li>

          <li className="divider" />

          <li
            className="d-flex flex-row justify-content-between align-items-center"
            role="menuitem"
          >
            <Link
              id="logout-button"
              to="/"
              onClick={() => {
                logout();
              }}
              className="log-out-link vismaicon-sm vismaicon-dynamic vismaicon vismaicon-logout"
              data-testid="logout-btn"
            >
              {t('logOut')}
            </Link>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default UserInfoNav;
