import { FC } from 'react';

interface IExternalLink {
  href: string;
  label: string;
  className?: string;
  dataTestId?: string;
}

const ExternalLink: FC<IExternalLink> = ({
  href,
  label,
  className,
  dataTestId,
}) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={className}
      data-testid={dataTestId}
    >
      {label}
    </a>
  );
};

export default ExternalLink;
