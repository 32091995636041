import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { ReactElement, FC, useContext, useState, useRef } from 'react';
import { AccountContext, RoleType } from 'auth/accountProvider';
import { useIsMounted } from 'usehooks-ts';
import { useProtectedRoute } from 'auth/useProtectedRoute';

interface IProtectedRouteProps {
  roles?: RoleType[];
  children?: ReactElement;
}

export const ProtectedRoute: FC<IProtectedRouteProps> = ({
  roles,
  children,
}) => {
  const location = useLocation();
  const [isSessChecked, setIsSessChecked] = useState<boolean>(false);
  const accountContext = useContext(AccountContext);
  const accountContextRef = useRef(accountContext);
  const { isAuthenticated, logout, hasRole } = accountContext;
  const isMounted = useIsMounted();

  const Spinner = (
    <div className="absolute-center">
      <div className="d-flex justify-content-center">
        <span className="spinner spinner-default-grey"></span>
      </div>
    </div>
  );

  useProtectedRoute(
    logout,
    accountContextRef,
    setIsSessChecked,
    isAuthenticated,
    isMounted
  );

  /*
   * When user tries to access protected route:
   * Shows spinner while getting session data;
   * Redirects to Log-in if not authenticated;
   * Redirects to home if required user roles not found;
   * Shows content.
   */
  if (!isAuthenticated && isSessChecked) {
    return (
      <Navigate to="/login" replace state={{ referer: location.pathname }} />
    );
  } else if (isAuthenticated) {
    if (!roles || hasRole(roles)) {
      return children ? children : <Outlet />;
    } else {
      return <Navigate to="/" />;
    }
  } else {
    return Spinner;
  }
};
