import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from 'components/form/inputs/Checkbox';
import { ReactComponent as ChartsImg } from 'assets/images/charts-image.svg';
import { AppContext } from 'appProvider';
import './Modal.scss';
import { AccountContext } from 'auth/accountProvider';

const Modal: FC = () => {
  const { t } = useTranslation();
  const { userInfo } = useContext(AccountContext);

  const { isModalOpen, setIsModalOpen, isModalTurnedOff, setIsModalTurnedOff } =
    useContext(AppContext);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      data-testid="modal"
      className={`modal ${isModalOpen ? 'fade in' : ''}`}
      tabIndex={-1}
      role="dialog"
    >
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              id="close-btn"
              type="button"
              className="close"
              aria-label="Close"
              onClick={handleClose}
              data-testid="close-btn"
            />
            <h1 className="title">
              <span>{t('hello') + ` ${userInfo.givenName},`}</span>
              {t('modalGreeting')}
            </h1>
          </div>
          <div className="modal-body d-flex justify-content-between align-items-center">
            <div className="left-side">
              <h2 className="sub-title">{t('whatIsLoginexTitle')}</h2>
              <p>{t('whatIsLoginexPart-1')}</p>
              <p>{t('whatIsLoginexPart-2')}</p>
              <p>{t('whatIsLoginexPart-3')}</p>
            </div>
            <div className="right-side">
              <ChartsImg />
            </div>
          </div>
          <Checkbox
            id="modal-checkbox"
            checked={isModalTurnedOff}
            onChange={() => {
              setIsModalTurnedOff(!isModalTurnedOff);
            }}
            label={t('modalCheckboxLabel')}
            dataTestId="modal-checkbox"
          />
          <div className="modal-footer d-flex justify-content-center">
            <button
              id="modal-continue"
              type="button"
              className="btn btn-primary btn-lg"
              onClick={handleClose}
            >
              {t('startWithLoginexModalBtnLabel')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
