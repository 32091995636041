import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet } from 'react-router-dom';
import ExternalLink from 'components/externalLink/ExternalLink';
import LoginexLogo from 'assets/images/loginex-logo.svg';

import './Login.scss';

export const LoginLayout: FC = () => {
  const { t } = useTranslation();

  const handelCookieClick = (e: any) => {
    e.preventDefault();
    document.getElementById('ot-sdk-btn')?.click();
  };

  return (
    <div className="login-page login-page-secondary">
      <div className="login-box">
        <div className="login-header">
          <img src={LoginexLogo} alt="Loginex" />
          <h1>Visma Loginex</h1>
        </div>
        <div className="login-content">{<Outlet />}</div>
      </div>

      <div className="login-footer">
        <ExternalLink
          href="https://www.visma.nl/connect-nl/"
          label="Visma"
          className="logo-footer visma-logo mr-24"
        />
        <ExternalLink
          href="https://www.ucgroup.nl"
          label="UC group"
          className="logo-footer uc-logo"
        />
      </div>

      <div className="alert login-footer-alert" role="alert">
        <ul>
          <li>© 2022 Visma</li>
          <li>
            <ExternalLink
              href="https://www.visma.nl/loginex/"
              label={t('aboutLoginex')}
            />
          </li>
          <li>
            <ExternalLink
              href="https://www.visma.com/privacy-statement/"
              label={t('Privacy statement')}
              dataTestId="privacy-statement"
            />
          </li>
          <li>
            <Link
              to=""
              className="cookies-link"
              onClick={handelCookieClick}
              data-testid="cookies-link"
            >
              {t('cookies')}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
