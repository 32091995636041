import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';
import {
  CognitoUser,
  AuthenticationDetails,
  IAuthenticationCallback,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';

import Pool from './userPool';
import axios from 'axios';

// Available Cognito user roles / groups
export type RoleType = 'UploadUser' | 'NormalUser' | 'ReportUser';
export const ROLES: { [name: string]: RoleType } = {
  UPLOAD: 'UploadUser' as RoleType,
  NORMAL: 'NormalUser' as RoleType,
  REPORT: 'ReportUser' as RoleType,
};

// Login error type
export type LoginErrorType =
  | 'NEW_PASSWORD_REQUIRED'
  | 'MFA_SMS_REQUIRED'
  | 'LOGIN_ERROR';
export const LOGIN_ERRORS: { [name: string]: LoginErrorType } = {
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED' as LoginErrorType,
  MFA_SMS_REQUIRED: 'MFA_SMS_REQUIRED' as LoginErrorType,
  LOGIN_ERROR: 'LOGIN_ERROR' as LoginErrorType,
};

export interface ILoginErrorReturn {
  type: LoginErrorType;
  user?: CognitoUser;
  message?: string;
}

export interface IUserInfo {
  givenName: string;
  familyName: string;
  organization: string;
  location: string;
  embedUrl: string;
  embedToken: string;
  reportId: string;
  exportReportId: string;
}

interface ILoginState {
  isMfaStarted: boolean;
  cognitoUser?: CognitoUser;
  phoneNr: string;
}

// Object structure for UserInfo lambda
export const defaultUserInfo: IUserInfo = {
  givenName: '',
  familyName: '',
  organization: '',
  location: '',
  embedUrl: '',
  embedToken: '',
  reportId: '',
  exportReportId: '',
};

const defaultLoginState: ILoginState = {
  isMfaStarted: false,
  cognitoUser: undefined,
  phoneNr: '',
};

const notSetUp = 'Not set up';

export interface IAccountContext {
  userInfo: IUserInfo;
  authenticate: (
    Username: string,
    Password: string
  ) => Promise<IAuthenticationCallback>;
  isAuthenticated: boolean;
  getSession: () => Promise<CognitoUserSession>;
  cognitoSession?: CognitoUserSession;
  hasRole: (role: RoleType | RoleType[]) => boolean;
  sendCode: (Username: string) => Promise<any>;
  resetPassword: (
    code: string,
    Username: string,
    Password: string
  ) => Promise<string>;
  logout: () => void;
  needsPasswordReset: boolean;
  needsMfaSmsCode: boolean;
  setNeedsMfaSmsCode: Dispatch<SetStateAction<boolean>>;
  loginState: ILoginState;
  checkUserInfo: (
    session: CognitoUserSession,
    resolve: any,
    reject: any
  ) => void;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
}

const defaultContext: IAccountContext = {
  userInfo: defaultUserInfo,
  authenticate: (_Username, _Password) =>
    Promise.reject<IAuthenticationCallback>(notSetUp),
  isAuthenticated: false,
  getSession: () => Promise.reject<CognitoUserSession>(notSetUp),
  hasRole: _role => false,
  sendCode: _Username => Promise.reject<any>(notSetUp),
  resetPassword: (_code, _Username, _Password) =>
    Promise.reject<string>(notSetUp),
  logout: () => {
    //
  },
  needsMfaSmsCode: false,
  needsPasswordReset: false,
  cognitoSession: undefined,
  loginState: defaultLoginState,
  checkUserInfo: () => {
    //
  },
  setIsAuthenticated: () => {
    //
  },
  setNeedsMfaSmsCode: () => {
    //
  },
};

const AccountContext: React.Context<IAccountContext> =
  createContext(defaultContext);

const AccountProvider: FC<{ children?: ReactNode }> = props => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [cognitoSession, setCognitoSession] = useState<
    CognitoUserSession | undefined
  >(undefined);
  const [userInfo, setUserInfo] = useState<IUserInfo>(defaultUserInfo);
  const [userRoles, setUserRoles] = useState<RoleType[]>([ROLES.NORMAL]);
  const [needsPasswordReset, setNeedsPasswordReset] = useState<boolean>(false);
  const [needsMfaSmsCode, setNeedsMfaSmsCode] = useState<boolean>(false);

  const [loginState, setLoginState] = useState<ILoginState>(defaultLoginState);
  /*
   * Refreshes Cognito session, calls userInfo lambda, returns Cognito session object.
   * This function is called on page reload and on session refresh interval.
   * To access session object in application use 'session' context variable instead.
   */
  const getSession: () => Promise<CognitoUserSession> = async () => {
    return new Promise<CognitoUserSession>((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (!user) {
        logout();
        reject('User disconnected');
        return;
      }

      user.getSession((error: Error | null, session: CognitoUserSession) => {
        if (error) {
          logout();
          reject(error.message);
          return;
        }

        checkUserInfo(session, resolve, reject);
      });
    });
  };

  /*
   * Calls userInfo lambda, sets session context, resolves parent Promise with Cognito session object.
   * Helper function used for not to duplicate actions of getUserInfo() promise fulfill.
   */
  const checkUserInfo = (
    session: CognitoUserSession,
    resolve: any,
    reject: any
  ) => {
    getUserInfo(session)
      .then(() => {
        login(session);
        resolve(session);
      })
      .catch(error => {
        logout();
        reject(error);
      });
  };

  /*
   * Calls userInfo lambda and saves user data to localstorage
   */
  const getUserInfo = async (session: CognitoUserSession) => {
    return new Promise((resolve, reject) => {
      const url = `${process.env.REACT_APP_DOMAIN}/backend/userinfo`;
      const jwtToken = session.getAccessToken().getJwtToken();

      axios
        .get(url, {
          headers: {
            Authorization: `${jwtToken}`,
          },
        })
        .then(response => {
          if (response.data) {
            const { data } = response;
            setUserInfo({
              givenName: data.userGivenName,
              familyName: data.userFamilyName,
              organization: data.userOrganisation,
              location: data.userLocation,
              embedUrl: data.embedUrl,
              embedToken: data.PowerbiEmbedToken,
              reportId: data.reportId,
              exportReportId: data.exportReportId,
            });
            resolve(data);
          } else {
            reject('Can not read user data');
          }
        })
        .catch(error => {
          if (process.env.NODE_ENV === 'development') {
            // Allow login without user data on development env.
            resolve(defaultUserInfo);
          } else {
            reject(error.message);
          }
        });
    });
  };

  /*
   * Login form.
   * Authenticates username with Cognito and calls userInfo lambda
   */
  const authenticate: (
    Username: string,
    Password: string
  ) => Promise<IAuthenticationCallback> = async (
    Username: string,
    Password: string
  ) => {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });

      const authenticationCallback: IAuthenticationCallback = {
        onSuccess: session => {
          setNeedsMfaSmsCode(true);
          checkUserInfo(session, resolve, reject);
        },
        onFailure: error => {
          logout();
          reject({ message: error.message, type: LOGIN_ERRORS.LOGIN_ERROR });
        },
        newPasswordRequired: () => {
          logout();
          setNeedsPasswordReset(true);
          reject({ type: LOGIN_ERRORS.NEW_PASSWORD_REQUIRED, user });
        },

        mfaRequired: (_challengeName, challengeParameters) => {
          setNeedsMfaSmsCode(true);
          setLoginState({
            cognitoUser: user,
            isMfaStarted: true,
            phoneNr: challengeParameters.CODE_DELIVERY_DESTINATION,
          });
        },
      };

      user.authenticateUser(authDetails, authenticationCallback);
    });
  };

  /*
   * Checks if user has any of the specified roles
   */
  const hasRole: (role: RoleType | RoleType[]) => boolean = roles => {
    if (!Array.isArray(roles)) {
      roles = [roles];
    }

    return !roles.length || roles.some(i => userRoles.includes(i));
  };

  /*
   * Sets session values to context, authenticates user
   */
  const login = (session: CognitoUserSession) => {
    setCognitoSession(session);
    setUserRoles(
      Object.values(session.getAccessToken().payload['cognito:groups'])
    );

    /* Use this for testing user roles: setUserRoles([ROLES.NORMAL]); */

    /*
     * allow to log-in without 2fa if in aws MFA is set to inactive,
     * also some config in SendVerificationCode.tsx handleSendCode() function
     */
    if (!loginState.isMfaStarted) {
      setIsAuthenticated(true);
    }
  };

  /*
   * Destroys Cognito session and un-authenticates user
   */
  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
    }
    setIsAuthenticated(false);

    setLoginState(defaultLoginState);
    setNeedsMfaSmsCode(false);
  };

  /*
   * Reset Password form.
   * Requests password change and sends security code email to a user.
   */
  const sendCode: (Username: string) => Promise<any> = async (
    Username: string
  ) => {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });

      user.forgotPassword({
        onSuccess: data => {
          resolve(data);
        },
        onFailure: error => {
          reject(error.message);
        },
        inputVerificationCode: data => {
          resolve(data);
        },
      });
    });
  };

  /*
   * Reset Password form.
   * Submits security code and new password.
   */
  const resetPassword: (
    code: string,
    Username: string,
    Password: string
  ) => Promise<string> = async (
    code: string,
    Username: string,
    Password: string
  ) => {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });

      user.confirmPassword(code, Password, {
        onSuccess: success => {
          resolve(success);
        },
        onFailure: error => {
          reject(error.message);
        },
      });
    });
  };

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        isAuthenticated,
        getSession,
        cognitoSession,
        userInfo,
        hasRole,
        sendCode,
        resetPassword,
        logout,
        needsPasswordReset,
        needsMfaSmsCode,
        loginState,
        checkUserInfo,
        setIsAuthenticated,
        setNeedsMfaSmsCode,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export { AccountProvider, AccountContext };
