import { FC, useContext, useEffect } from 'react';
import { AccountContext } from 'auth/accountProvider';
import './Inmoment.scss';

const Inmoment: FC = () => {
  const { cognitoSession } = useContext(AccountContext);
  const ACCOUNT_TOKEN = 'NPS-EU-75329534';

  useEffect(() => {
    const wootricScript = document.createElement('script');
    wootricScript.src = 'https://cdn.wootric.com/wootric-sdk.js';
    document.body.appendChild(wootricScript);

    if (cognitoSession?.getAccessToken().payload.username) {
      window.wootricSettings = {
        email: `${cognitoSession.getAccessToken().payload.username}`,
        /* created_at: 1234567890, // The current user's sign-up date as a 10 digit Unix timestamp in seconds. OPTIONAL */
        account_token: ACCOUNT_TOKEN,
        disclaimer: {
          text: ' ',
          link: 'https://www.visma.com/privacy/privacy-statement-inmoment/',
          link_word: 'Privacy Statement',
        },
      };

      if (window.wootric) {
        window.wootric('run');
      }
    }

    return () => {
      document.body.removeChild(wootricScript);
    };
  }, [cognitoSession]);

  return <></>;
};

export default Inmoment;
