import { Link } from 'react-router-dom';
import Popup from 'components/popup/Popup';
import DownloadForm from 'pages/download/DownloadForm';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

/*
 * This component is a Main menu button item which opens popup instead of redirecting to a route
 */
export const DownloadLink = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalDisabled, setIsModalDisabled] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  // isSubmitted state tells deeper components to submit form data.
  const handleAction = () => {
    setIsSubmitted(true);
  };

  return (
    <>
      <Link
        to=""
        className="nav-item fl-menu-item"
        role="menuitem"
        onClick={e => {
          e.preventDefault();
          setIsModalOpen(true);
        }}
      >
        {t('Download report')}
      </Link>
      <Popup
        title={t('Download report')}
        isPopupOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
        }}
        handleAction={handleAction}
        cancelAction={() => {
          setIsModalOpen(false);
        }}
        leftButtonTitle={t('Download')}
        isModalDisabled={isModalDisabled}
      >
        <DownloadForm
          {...{
            isSubmitted,
            setIsSubmitted,
            setIsModalDisabled,
            setIsModalOpen,
            isModalOpen,
          }}
        />
      </Popup>
    </>
  );
};
