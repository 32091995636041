import { FC, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import FormError, { errorClass } from 'components/form/formError';
import { ErrorMessage } from '@hookform/error-message';
import { AccountContext } from 'auth/accountProvider';
import { useTranslation } from 'react-i18next';
import { useValidation } from 'components/form/useValidation';
import InfoAlert from 'components/alert/InfoAlert';
import { CognitoUser } from 'amazon-cognito-identity-js';

const ResetPassword: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { resetPassword } = useContext(AccountContext);
  const rules = useValidation();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const [cognitoError, setCognitoError] = useState<string>('');
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const location = useLocation();
  const locationState = location.state as {
    resetUsername: string;
    forceReset?: boolean;
    user?: CognitoUser;
  };

  useEffect(() => {
    if (!locationState?.resetUsername) {
      navigate('/login');
    }
  }, [locationState, navigate]);

  const setError = (error: string) => {
    setCognitoError(t(error, t('Reset password unknown untranslated error')));
  };

  const handleResetPassword = (form: any) => {
    resetPassword(
      form.securityCode,
      locationState.resetUsername,
      form.newPassword
    )
      .then(() => {
        navigate('/login', { state: { isResetPassword: true } });
      })
      .catch((error: any) => {
        setError(error);
      });
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(handleResetPassword)}>
      <InfoAlert
        text={t('reset password explanation')}
        customClass="custom-alert"
      />
      <div
        className={`form-group required ${errorClass(errors, 'securityCode')}`}
      >
        <label htmlFor="input-code" className="control-label">
          {t('code')}
        </label>
        <input
          id="input-code"
          data-testid="input-code"
          type="text"
          placeholder="000000"
          className="form-control"
          {...register('securityCode', rules.required)}
        />
        <ErrorMessage {...{ errors }} name="securityCode" as={FormError} />
      </div>

      <div
        className={`form-group required ${errorClass(errors, 'newPassword')}`}
      >
        <label htmlFor="input-new-password" className="control-label">
          {t('new password')}
        </label>
        <div className="password-toggle-container">
          <button
            id="new-password-toggler"
            data-testid="new-password-toggler"
            type="button"
            className="password-toggler"
            onClick={() => {
              setIsPasswordVisible(!isPasswordVisible);
            }}
          />
          <input
            id="input-new-password"
            data-testid="input-new-password"
            type={isPasswordVisible ? 'text' : 'password'}
            placeholder={t('new password')}
            className="form-control  password-toggle"
            {...register('newPassword', rules.required)}
          />
        </div>
        <ErrorMessage {...{ errors }} name="newPassword" as={FormError} />
      </div>

      <div
        className={`form-group required ${errorClass(
          errors,
          'repeatPassword'
        )}`}
      >
        <label htmlFor="input-repeat-password" className="control-label">
          {t('repeat new password')}
        </label>
        <div className="password-toggle-container">
          <button
            id="repeat-password-toggler"
            data-testid="repeat-password-toggler"
            type="button"
            className="password-toggler"
            onClick={() => {
              setIsPasswordVisible(!isPasswordVisible);
            }}
          />
          <input
            id="input-repeat-password"
            data-testid="input-repeat-password"
            type={isPasswordVisible ? 'text' : 'password'}
            placeholder={t('repeat new password')}
            className="form-control  password-toggle"
            {...register('repeatPassword', {
              ...rules.required,
              validate: value =>
                value === getValues('newPassword') ||
                `${t('Passwords do not match')}`,
            })}
          />
        </div>
        <ErrorMessage {...{ errors }} name="repeatPassword" as={FormError} />
      </div>

      <div className="form-group">
        <button
          id="submit-reset-password"
          data-testid="submit-reset-password"
          type="submit"
          className="btn btn-primary btn-login"
        >
          {t('reset password')}
        </button>
        {cognitoError && <FormError>{cognitoError}</FormError>}
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col-6">
            <Link to="/send-code" className="btn btn-link" id="back-button">
              <span className="vismaicon vismaicon-move-left-circle" />
              {t('back')}
            </Link>
          </div>
          <div className="col-6 d-flex align-items-center justify-content-end">
            <Link
              to="/send-code"
              state={{ resetUsername: locationState?.resetUsername }}
            >
              {t('resend code')}
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ResetPassword;
