import axios, { AxiosResponse } from 'axios';
import format from 'date-fns/format';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { registerLocale } from 'react-datepicker';
import nl from 'date-fns/locale/nl';
import enUS from 'date-fns/locale/en-US';
import { AccountContext } from 'auth/accountProvider';
import { useTranslation } from 'react-i18next';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export const useDownloadForm = (
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
) => {
  const { t } = useTranslation();

  const DATE_FORMAT = 'MM/yyyy';
  const YEAR_FORMAT = 'yyyy';
  const MONTH_FORMAT = 'M';
  const POLLING_INTERVAL = 2000; // 2 seconds
  const DOWNLOAD_FILENAME = 'Loginex-report.pptx';

  // Date picker locales
  registerLocale('nl', nl);
  registerLocale('en', enUS);

  const { cognitoSession, userInfo } = useContext(AccountContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [downloadError, setDownloadError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const resetForm = () => {
    setValue('clientCode', '');
    setStartDate(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    setEndDate(new Date());
    setDownloadError('');
    setIsLoading(false);
  };

  /*
   * Calls exports lambda which starts file generation and gives exportId in response.
   * This exportId is used when polling / checking generation status.
   */
  const getExportId = async (
    clientCode: string
  ): Promise<{
    session: CognitoUserSession;
    data: { reportId: string; exportId: string };
  }> => {
    return new Promise((resolve, reject) => {
      const url = `${process.env.REACT_APP_DOMAIN}/backend/reports/${userInfo.exportReportId}/exports`;
      const jwtToken = cognitoSession?.getAccessToken().getJwtToken();

      axios
        .post(
          url,
          {
            clientCode: `${clientCode}`,
            startYear: `${format(startDate || new Date(), YEAR_FORMAT)}`,
            startMonth: `${format(startDate || new Date(), MONTH_FORMAT)}`,
            endYear: `${format(endDate || new Date(), YEAR_FORMAT)}`,
            endMonth: `${format(endDate || new Date(), MONTH_FORMAT)}`,
          },
          {
            headers: {
              authorization: `${jwtToken}`,
            },
          }
        )
        .then(response => {
          if (!cognitoSession) {
            reject('No cognito session');
            return;
          }
          if (response.data?.reportId && response.data?.exportId) {
            resolve({ session: cognitoSession, data: response.data });
          } else {
            reject('Can not get Export Id');
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });
  };

  /*
   * Calls exports lambda and receives file content encoded in base64
   * or 202 status if generation is not finished yet.
   * Takes reportId & exportId from getExportId() response.
   */
  const getEncodedFile = async (
    session: CognitoUserSession,
    reportId: string,
    exportId: string
  ): Promise<AxiosResponse> => {
    return new Promise((resolve, reject) => {
      const url = `${process.env.REACT_APP_DOMAIN}/backend/reports/${reportId}/exports/${exportId}`;
      const jwtToken = session?.getAccessToken().getJwtToken();

      axios
        .get(url, {
          headers: {
            authorization: `${jwtToken}`,
          },
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  /*
   * Polls getEncodedFile() periodically (with interval of 2 sec) until receives response status 200.
   * Then calls downloadPptx() to start download in a browser.
   * Takes reportId & exportId from getExportId() response.
   */
  const PERIODIC_RESPONSE_STATUS = 200;
  const pollDownload = (
    session: CognitoUserSession,
    reportId: string,
    exportId: string
  ) => {
    getEncodedFile(session, reportId, exportId)
      .then((response: AxiosResponse) => {
        if (response.status === PERIODIC_RESPONSE_STATUS) {
          resetForm();
          if (response.data?.file) {
            downloadPptx(response.data.file);
            setIsModalOpen(false);
          } else {
            setDownloadError(t('downloadError'));
          }
        } else {
          setTimeout(() => {
            pollDownload(session, reportId, exportId);
          }, POLLING_INTERVAL);
        }
      })
      .catch(() => {
        setDownloadError(t('downloadError'));
        setIsLoading(false);
      });
  };

  /*
   * Takes base64 encoded file content of .pptx type, creates data href and starts download.
   */
  const downloadPptx = (base64: string) => {
    const filename = DOWNLOAD_FILENAME;
    const link = document.createElement('a');
    link.href =
      'data:@file/vnd.openxmlformats-officedocument.presentationml.presentation;base64,' +
      base64;
    link.download = filename;
    link.click();
  };

  /*
   * Download click handler
   * Calls getExportId() and passes received exportId & reportId to pollDownload() periodical polling call
   */
  const handleDownload = (form: FieldValues) => {
    setIsLoading(true);
    setDownloadError('');

    getExportId(form.clientCode.trim())
      .then(({ session, data }) => {
        pollDownload(session, data.reportId, data.exportId);
      })
      .catch(() => {
        setDownloadError(t('downloadError'));
        setIsLoading(false);
      });
  };

  /*
   * Handles validation message for Start date and End dates
   * Transforms dates with equal days and time to avoid bugs
   */
  const handleDateValidation = (fromDate: Date, toDate: Date) => {
    const fromTimeStamp = new Date(fromDate.setHours(0, 0, 0, 0)).setDate(0);
    const toTimeStamp = new Date(toDate.setHours(0, 0, 0, 0)).setDate(0);
    if (fromDate && toDate && fromTimeStamp > toTimeStamp) {
      setDownloadError(t('dateRangeError'));
    } else {
      setDownloadError('');
    }
  };

  return {
    resetForm,
    isLoading,
    handleSubmit,
    handleDownload,
    handleDateValidation,
    register,
    errors,
    DATE_FORMAT,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    downloadError,
  };
};
