import { useEffect } from 'react';

export const useProtectedRoute = (
  logout: any,
  accountContextRef: any,
  setIsSessChecked: any,
  isAuthenticated: any,
  isMounted: any
) => {
  const MINUTE_IN_MS = 60000; // Milliseconds
  const SESSION_REFRESH_AMOUNT_IN_MINUTES = 20; // Amount of minutes
  const SESSION_REFRESH_INTERVAL =
    MINUTE_IN_MS * SESSION_REFRESH_AMOUNT_IN_MINUTES; // 20 minutes in milliseconds

  /*
   * Cross-tab logout. Sign-outs a user in all browser tabs.
   */
  useEffect(() => {
    const handleCrossTabLogout = (e: StorageEvent) => {
      if (
        e.key?.includes('CognitoIdentityServiceProvider') &&
        e.key?.includes('accessToken') &&
        e.oldValue &&
        !e.newValue
      ) {
        logout();
      }
    };
    window.addEventListener('storage', handleCrossTabLogout);

    return () => {
      window.removeEventListener('storage', handleCrossTabLogout);
    };
  }, [logout]);

  /*
   * Checks for expired session and refreshes it with Refresh token periodically
   */
  useEffect(() => {
    const refreshSessionInterval = setInterval(() => {
      accountContextRef.current.getSession().then().catch();
    }, SESSION_REFRESH_INTERVAL);

    return () => clearInterval(refreshSessionInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * On page reload, checks for saved session and re-authenticates the user
   */
  useEffect(() => {
    setIsSessChecked(false);
    const mounted = isMounted();
    if (!isAuthenticated && mounted) {
      accountContextRef.current
        .getSession()
        .then(() => {
          setIsSessChecked(true);
        })
        .catch(() => {
          setIsSessChecked(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isMounted]);
};
